import React, { useEffect, useRef } from "react";
import "./FlipCard.css";
import Poster from "../../assets/images/poster.png";
import { useAnimation, useInView, motion } from "framer-motion";
function FlipCard() {
  const control = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref);
  const variant = {
    visible: {
      opacity: 1,
    },
    hidden: { opacity: 0 },
  };
  useEffect(() => {
    if (isInView) {
      control.start("visible");
    }
  }, [control, isInView]);

  return (
    <div className="flip-container w-screen h-screen bg-gradient">
      <div className="relative w-full h-full">
        <motion.div
          variants={variant}
          initial="hidden"
          animate={control}
          transition={{ ease: "easeOut", duration: 0.8, delay: 0.3 }}
          className="card"
          ref={ref}
        >
          <div className="imgBox">
            <div className="bark"></div>
            <img src={Poster} alt="poster" />
          </div>
          <div className="details">
            <div className="text-xl text-[#1bbc9b] text-center heading-card">
              Here we go !!!
            </div>
            <div className="text-xl text-[#c0392b] text-center heading-card">
              BORN TO BE YOURS
            </div>
            <p>Gửi anh BornG,</p>
            <p>chúc anh tuổi mới</p>
            <p>
              có sức khoẻ như <span className="text-red-500">Siêu Nhân</span>
            </p>
            <p>
              nhiểu tiền như <span className="text-violet-500">Đạo Chích</span>
            </p>
            <p>
              thông minh, sáng suốt như{" "}
              <span className="text-orange-500">Thần Đồng</span>
            </p>
            <p>
              tránh <span className="text-purple-700">Hiểm Hoạ</span>
            </p>
            <p>
              và không <span className="text-green-500">Si</span>
              <span className="text-yellow-500">êu l</span>
              <span className="text-pink-400">ừa</span> sủi stream viewer
            </p>
            <p className="text-right">Happy Birthday, BornG!</p>
            <p className="text-right">From Beckem with ♥</p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default FlipCard;
