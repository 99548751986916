import React, { useEffect, useState } from "react";
import "./Cube.css";
function Cube() {
  const [typeCube, setTypeCube] = useState(true);

  return (
    <div className="w-screen h-screen bg-gradient">
      <div className="relative">
        <div
          id="container"
          className="cursor-pointer"
          onClick={() => setTypeCube((type) => !type)}
        >
          <div
            id="stage"
            style={{ transform: `translateZ(${typeCube ? "0" : "-200px"})` }}
          >
            <div
              id="shape"
              className={`backfaces ${typeCube ? "cube" : "ring"}`}
            >
              <div className="plane one"></div>
              <div className="plane two"></div>
              <div className="plane three"></div>
              <div className="plane four"></div>
              <div className="plane five"></div>
              <div className="plane six"></div>
              <div className="plane seven"></div>
              <div className="plane eight"></div>
              <div className="plane nine"></div>
              <div className="plane ten"></div>
              <div className="plane eleven"></div>
              <div className="plane twelve"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cube;
