import { FullPage, Slide } from "react-full-page";
import Hero from "./components/Hero/Hero";
import Cube from "./components/Cube/Cube";
import FlipCard from "./components/FlipCard/FlipCard";
import Count from "./components/Count/Count";
import { useWindowSize } from "./hooks/useWindowSize";
import BirthDaySong from "./assets/musics/birthdaySong1.mp3";
import { useAudio } from "./hooks/useAudio";

function App() {
  //audio
  const [playing, toggle, firstTime] = useAudio(BirthDaySong);

  //scroll to top when reload
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  //check device
  const size = useWindowSize();

  if (size.width < 750)
    return (
      <>
        <Hero firstTime={firstTime} playing={playing} toggle={toggle} />
        <Cube />
        <FlipCard />
        <Count />
        <Slide />
      </>
    );
  else
    return (
      <>
        <FullPage controls={CustomControls(firstTime)}>
          <Slide className="overflow-hidden">
            <Hero firstTime={firstTime} playing={playing} toggle={toggle} />
          </Slide>
          <Slide className="overflow-hidden">
            <Cube />
          </Slide>
          <Slide className="overflow-hidden">
            <FlipCard />
          </Slide>
          <Slide className="overflow-hidden">
            <Count />
          </Slide>
        </FullPage>
      </>
    );
}

export default App;

const CustomControls =
  (firstTime) =>
  ({ getCurrentSlideIndex, scrollToSlide }) => {
    if (firstTime) return null;

    const currentSlideIndex = getCurrentSlideIndex();

    return (
      <>
        <div className="fixed right-[2%] top-1/2 z-50">
          {[0, 1, 2, 3].map((index) => (
            <div
              key={index}
              onClick={() => scrollToSlide(index)}
              className={`control-item ${
                currentSlideIndex === index && "bg-white"
              }`}
            ></div>
          ))}
        </div>
      </>
    );
  };
