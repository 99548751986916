import React from "react";
import "./Cassette.css";
import PlayButton from "../../assets/images/play-button.png";
import PauseButton from "../../assets/images/pause-button.png";
function Cassette({ playing, toggle }) {
  const onClickPlay = () => {
    toggle();
  };

  return (
    <div className="tape shadow-xl !z-[1000]">
      <div
        className="button-radio absolute top-[50%] -translate-y-1/2 right-[1%] z-50 cursor-pointer"
        onClick={onClickPlay}
      >
        <img
          src={playing ? PauseButton : PlayButton}
          alt="button"
          className="lg:w-16 md:w-12 sm:w-8 w-6 animate-pulse"
        />
      </div>
      <div className="screws">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </div>
      <div className="outdent"></div>
      <div className="holes">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </div>

      <div className="label">
        <div className="inner">
          <div className="lines">
            <i></i>
            <i></i>
            <i></i>
          </div>
          <div className="stripes">
            <i></i>
            <i></i>
          </div>
          <div className="cutout">
            <div className={`wheel ${!playing ? "!animate-none" : ""}`}>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
            </div>
            <div className={`wheel ${!playing ? "!animate-none" : ""}`}>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
            </div>
            <div className="window">
              <div
                className={`spool-left ${!playing ? "!animate-none" : ""}`}
              ></div>
              <div
                className={`spool-right ${!playing ? "!animate-none" : ""}`}
              ></div>
              <div
                className={`reel-left ${!playing ? "!animate-none" : ""}`}
              ></div>
              <div
                className={`reel-right ${!playing ? "!animate-none" : ""}`}
              ></div>
            </div>
            <div className="ticks"></div>
          </div>
          <p className="quality">
            High Quality
            <br />
            Sound
          </p>
          <p className="type">
            X<span>27</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Cassette;
