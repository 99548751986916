import React, { useEffect, useRef, useState } from "react";
import { useAnimation, useInView, motion } from "framer-motion";
import "./Count.css";
function Count() {
  const control = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref);
  const variant = {
    visible: {
      opacity: 1,
    },
    hidden: { opacity: 0 },
  };
  useEffect(() => {
    if (isInView) {
      control.start("visible");
    }
  }, [control, isInView]);

  const [day, setDay] = useState("00");
  const [hour, setHour] = useState("00");
  const [minute, setMinute] = useState("00");
  const [second, setSecond] = useState("00");

  useEffect(() => {
    const now = new Date();
    const NextBirthday = new Date(
      `April 01 ${
        new Date().getFullYear() + (now.getMonth() >= 3 ? 1 : 0)
      } 00:00:00`
    );
    function updateCountdown() {
      const currentTime = new Date();
      const diff = NextBirthday - currentTime;
      const d = Math.floor(diff / 1000 / 60 / 60 / 24);
      const h = Math.floor(diff / 1000 / 60 / 60) % 24;
      const m = Math.floor(diff / 1000 / 60) % 60;
      const s = Math.floor(diff / 1000) % 60;
      setDay(d);
      setHour(h < 10 ? "0" + h : h);
      setMinute(m < 10 ? "0" + m : m);
      setSecond(s < 10 ? "0" + s : s);
    }
    const interval = setInterval(() => {
      updateCountdown();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-screen h-screen bg-gradient">
      <div className="relative h-full w-full">
        <div className="text-stroke pt-8">Next birthday</div>
        <motion.div
          ref={ref}
          variants={variant}
          initial="hidden"
          animate={control}
          transition={{ ease: "easeOut", duration: 1, delay: 0.5 }}
          className=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          <div className="flex flex-col gap-2 pattern-text items-center justify-center">
            <div className="flex items-center space-x-1">
              <span>{day}</span>
              <span className="md:text-[40px] text-[28px]">Days</span>
            </div>
            <div className="flex items-center space-x-1">
              <span>{hour}</span>
              <span className="md:text-[40px] text-[28px]">Hours</span>
            </div>

            <div className="flex items-center space-x-1">
              <span>{minute}</span>
              <span className="md:text-[40px] text-[28px]">Minutes</span>
            </div>
            <div className="flex items-center space-x-1">
              <span>{second}</span>
              <span className="md:text-[40px] text-[28px]">Seconds</span>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Count;
